const BubbleBusterGame = () => {
    return (
      <iframe
        src="https://play2048.co/"
        title="T-Rex Game"
        className="w-full h-full"
        style={{ border: "none", height: "100vh" }}
      />
    );
  };
  
  export default BubbleBusterGame;
  